import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const TrueAndFalseQuestions = (props) => {
	const { t } = useTranslation();
	const { onClick, values, id, checkedStatus } = props;

	const [isHoveringTrue, setIsHoveringTrue] = useState(false);
	const [isHoveringFalse, setIsHoveringFalse] = useState(false);

	const handleMouseEnter = (e) => {
		e.currentTarget.attributes.name.value == "true"
			? setIsHoveringTrue(true)
			: setIsHoveringFalse(true);
	};
	const handleMouseLeave = (e) => {
		e.currentTarget.attributes.name.value == "true"
			? setIsHoveringTrue(false)
			: setIsHoveringFalse(false);
	};

	return (
		<div className="true-false row my-1 align-items-center gap-1 gap-lg-5">
			<span className="col-8">{values?.value}</span>
			<label
				htmlFor={`${id}-0`}
				name="true"
				className={`col-1 englease--click englease__radius--f-8 p-1 p-lg-3
       `}
				style={{
					backgroundColor:
						checkedStatus?.is_true == 1
							? `rgba(230, 247, 232, 1)`
							: "rgba(248, 248, 248, 1)",
					border:
						checkedStatus?.is_true == 1 || isHoveringTrue
							? `1px solid rgba(0, 176, 26, 1)`
							: "rgba(248, 248, 248, 1)",
				}}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={onClick}
			>
				<input
					id={`${id}-0`}
					value="1"
					type="radio"
					name="true"
					defaultChecked={checkedStatus?.is_true == 1 ? true : false}
					className=""
				/>
				<div className="d-flex align-items-center justify-content-center">
					{/* {values?.value.toLowerCase() == "true" ? ( */}
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						fill={`${isHoveringTrue && "#00B01A"}`}
						className={`${
							checkedStatus?.is_true == 1 && "success"
						} bi bi-check-circle`}
						viewBox="0 0 16 16"
					>
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
						<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
					</svg>
				</div>
			</label>
			{/* ) : ( */}
			<label
				htmlFor={`${id}-1`}
				name="false"
				className={`col-1 englease--click englease__radius--f-8 p-1 p-lg-3
       `}
				style={{
					backgroundColor:
						checkedStatus?.is_true == 0
							? `rgba(252, 235, 233, 1)`
							: "rgba(248, 248, 248, 1)",
					border:
						checkedStatus?.is_true == 0 || isHoveringFalse
							? `1px solid rgba(227, 54, 36, 1)`
							: "rgba(248, 248, 248, 1)",
				}}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onClick={onClick}
			>
				<input
					id={`${id}-1`}
					value="0"
					type="radio"
					name="false"
					defaultChecked={checkedStatus?.is_true == 0 ? true : false}
					className=""
				/>
				<div className="d-flex align-items-center justify-content-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						fill={`${isHoveringFalse && "#E33624"}`}
						className={`${
							checkedStatus?.is_true == 0 && "fail"
						} bi bi-x-circle`}
						viewBox="0 0 16 16"
					>
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
						<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
					</svg>
					{/* )} */}
				</div>
			</label>
		</div>
	);
};
export default TrueAndFalseQuestions;
