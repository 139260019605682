import React, { useEffect, useState } from "react";
import { actions } from "../../redux/store";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import CertificateModal from "../CertificateModal";

import "./styles.scss";

const AssessmentResult = (props) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const currentLocation = useLocation();
	const enterpriseName = localStorage.getItem("enterpriseName");
	const [userRequestedCertificate, setUserRequestedCertificate] =
		useState(false);
	const studentResult = useSelector((state) => state.assessmentResult);
	const placementResult = useSelector((state) => state.placementResult);

	const handleRetake = () => {
		dispatch(actions.clearAnswerList([]));
		navigate(currentLocation.state.page.name, {
			state: sessionStorage.getItem("testId"),
		});
	};

	const handleAvailableClasses = () => {
		dispatch(actions.clearAnswerList([]));
		if (placementResult.type === "placement") {
			sessionStorage.clear();
			const ebStudent = localStorage.getItem("isEb");
			enterpriseName == "Talaaqa"
				? navigate(
						`/student-commitment?university=${placementResult?.level?.university}`,
						{
							replace: true,
							state: {
								page: {
									name: location?.pathname,
									key: location.state,
									userLvl: placementResult?.level?.level?.order,
								},
							},
						}
				  )
				: location.assign(
						`${process.env.REACT_APP_REDIRECTION_LINK}/know-you`,
						{
							replace: true,
						}
				  );
		} else {
			sessionStorage.clear();
			location.replace(`${process.env.REACT_APP_REDIRECTION_LINK}`);
		}
	};

	const testResult = () => {
		let score = "";
		if (placementResult?.type === "placement") {
			score = `${placementResult?.level?.level?.name} (${placementResult?.level?.level?.abbreviation})`;
		} else {
			score = `${Number(studentResult?.score?.percentage)}%`;
		}
		// let homeworkPassed = studentResult?.score?.is_passed;
		// window["dataLayer"].push({
		// 	event: homeworkPassed ? "homework_passed" : "homework_failed",
		// 	level: userLevel,
		// });
		return score;
	};

	return (
		<div className="assessment-result">
			{enterpriseName === "Talaaqa" &&
			placementResult?.level?.level?.order < 0 ? (
				<>
					<i className="assessment-result__sad-icon"></i>
					<h4 className="assessment-result__title">
						{t("talaaqa-blocker.sub-title.disqualified")}
					</h4>
				</>
			) : (
				<>
					<h4 className="assessment-result__title">
						{placementResult?.type !== "placement"
							? t("assessment-result.test-result")
							: t("placement-result.level-set")}
					</h4>
					<p>
						{placementResult?.type !== "placement"
							? studentResult?.score?.is_passed === false
								? t("assessment-result.fail.not-completed_successfully")
								: t("assessment-result.success.completed_successfully")
							: t("placement-result.congrats")}
					</p>
					<i
						className={
							placementResult?.type !== "placement"
								? studentResult?.score?.is_passed === false
									? "assessment-result__sad-icon"
									: "assessment-result__celebration-icon"
								: "assessment-result__celebration-icon"
						}
					></i>
					<p>
						{placementResult?.type !== "placement"
							? t("assessment-result.your-score-is")
							: t("placement-result.your-level-is")}
					</p>
					<p
						className={
							placementResult?.type !== "placement"
								? ""
								: "assessment-result__score"
						}
					>
						{testResult()}
					</p>
					{studentResult?.type === "exit" &&
					studentResult?.score?.is_passed === true ? (
						<span className="englease--f-12">
							<CertificateModal
								classPassed={"iET-celebration englease__bg--contain iET-m80"}
								buttonName={t("classes-list.student-progress.certificate-btn")}
								title={t("classes-list.student-progress.congrats")}
								// currentUserLevel={userLevel}
								eligible={true}
								requested={userRequestedCertificate}
								descriptionMessage={
									userRequestedCertificate
										? t("classes-list.student-progress.claimed-before")
										: t("classes-list.student-progress.certificate-ready")
								}
							/>
						</span>
					) : (
						<>
							<div>
								<p className="">
									{placementResult?.type !== "placement"
										? studentResult?.score?.is_passed === false
											? t("assessment-result.fail.alright-let's-try-again")
											: t("assessment-result.success.congrats")
										: t("placement-result.your-dream-is-one-step-closer")}
								</p>

								{studentResult?.score?.is_passed === false &&
									studentResult?.type !== "exit" && (
										<button
											className="btn englease__radius--f-8 assessment-result__retakeTest"
											onClick={handleRetake}
										>
											{t("assessment-result.fail.retake-test")}
										</button>
									)}
							</div>
							{placementResult?.type !== "placement" ? (
								<>
									<button
										className="btn mx-2 border-0 englease__bg--main englease__radius--f-8 englease__txt--white"
										onClick={handleAvailableClasses}
									>
										{t("assessment-result.available-classes")}
									</button>
								</>
							) : (
								<button
									className="btn mx-2 border-0 englease__bg--main englease__radius--f-8 englease__txt--white"
									onClick={handleAvailableClasses}
								>
									{t("placement-result.view-all-classes")}
								</button>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
};

export default AssessmentResult;
