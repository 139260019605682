import React from "react";
import { useTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import AttachmentAssessment from "../../AttachmentAssessment";
import "./styles.scss";

const FillGapsSelection = (props) => {
	const {
		questionsList,
		answersList,
		questionAttachment,
		handleChoiceAnswer,
		answersFromRedux,
	} = props;
	const { t } = useTranslation();
	let seperateAnswers = [];
	let attachmentType = questionAttachment?.media_type;
	let attachmentMedia = questionAttachment?.media;
	const SEPARATOR = "#";

	answersList.map((answers) => {
		answers.map((ele) => {
			if (ele !== "" && ele !== undefined && ele.length > 0) {
				seperateAnswers.push(ele);
				return seperateAnswers.sort();
			}
		});
	});

	const disabledButtonAfterClicking = (event) => {
		event.target.disabled = true;
	};

	return (
		<div className="row">
			<section
				className={
					attachmentType !== undefined
						? "col-md-6 col-xs-12 fill-gaps-selection__question"
						: "col-12 col-md-6 fill-gaps-selection__question"
				}
			>
				{/* <section className="col-12 englease__txt--main fill-gaps-selection__title">
					<h5>{t("fill-gaps.selection-title")}</h5> */}
				{/*<p dangerouslySetInnerHTML={{ __html: `${questionTitle}` }}></p>*/}
				{/* </section> */}

				<section className="col-12">
					<p
						dangerouslySetInnerHTML={{ __html: questionsList.join("<br>") }}
					></p>
					<div className="col-12">
						<ToggleButtonGroup
							type="checkbox"
							exclusive
							value={answersFromRedux}
							onChange={handleChoiceAnswer}
						>
							{seperateAnswers?.map((option, idx) => (
								<ToggleButton
									key={idx}
									value={`${idx}${SEPARATOR}${option}`}
									id={option}
									className={`answerChoice`}
									onClick={disabledButtonAfterClicking}
								>
									{option}
								</ToggleButton>
							))}
						</ToggleButtonGroup>
					</div>
				</section>
			</section>

			{/*section for the attachment */}
			{attachmentType !== undefined && (
				<section className="col-md-6 col-xs-12 ">
					<AttachmentAssessment type={attachmentType} media={attachmentMedia} />
				</section>
			)}
		</div>
	);
};
export default FillGapsSelection;
