import React, { useEffect, useState } from "react";
import { t } from "i18next";
import {
	getExitQuestions,
	getPlacementQuestions,
	getAssessmentQuestions,
} from "../../services/exercisesService";
import ConfirmationModal from "../../component/reuseable-component/Modal";
import ExercisesEngine from "../../component/ExercisesEngine";

const Exercise = () => {
	const { type, testId } = sessionStorage;
	const [modalShow, setModalShow] = useState(false);
	const [questionsList, setQuestionsList] = useState([]);
	const [placementTitle, setPlacementTitle] = useState("");
	const [questionsTotalCount, setQuestionsTotalCount] = useState(0);
	// on exit button click to show modadl.
	const AssessmentQ = async () => {
		// : await getAssessmentQuestions(1503);
		// : await getAssessmentQuestions(595);
		let res;
		if (type === "placement") res = await getPlacementQuestions(1);
		if (type === "assessment") res = await getAssessmentQuestions(testId);
		if (type === "exit") res = await getExitQuestions();
		setQuestionsList(res?.questions);
		setQuestionsTotalCount(res?.questions?.length);
		setPlacementTitle(res?.assessment_name);
	};

	// Get assessment questions from service.
	useEffect(() => {
		AssessmentQ();
	}, [testId]);

	const handleExit = () => {
		setModalShow(true);
	};

	// modal content.
	function modalContent() {
		return (
			<>
				<img className="" src="/assets/icons/Frame.svg" />
				<section className="mt-4 englease englease--f-16 d-flex flex-column align-items-center">
					<p className="attend-assessment__modal-title ">
						{t("confirmation-message.are-you-sure")}
					</p>
					<p className="attend-assessment__modal-sub-title ">
						{t("confirmation-message.your-Progress-Wil-not-be-saved")}
					</p>
				</section>
			</>
		);
	}

	// unsave the assessment and move back
	const ModalConfirm = () => {
		// window["dataLayer"].push({ event: `unsave${type}` });
		history.back();
	};

	return (
		<div>
			<div
				className="d-flex align-items-center gap-3 justify-content-between"
				dir="ltr"
			>
				<p className="mb-0 englease englease--f-24 englease__txt--dark text-left">
					{type === "placement" ? "Placement Test" : "Exercise"}
				</p>
				{type !== "placement" && (
					<div className="englease--click" onClick={handleExit}>
						<i className="iET-Close englease__bg--contain iET-s24"></i>
					</div>
				)}
			</div>

			<div className="">
				{Array.isArray(questionsList) && questionsList?.length > 0 && (
					<ExercisesEngine
						type={`${type}`}
						questionsTotalCount={questionsTotalCount}
						questionsList={questionsList}
						placementTitle={placementTitle}
					/>
				)}
			</div>

			<ConfirmationModal
				show={modalShow}
				size="md"
				ok={t("confirmation-message.yes-sure")}
				cancel={t("confirmation-message.continue")}
				confirm={() => ModalConfirm()}
				onHide={() => setModalShow(false)}
				addclasses="exitBtn"
				type="exitTest"
			>
				{modalContent()}
			</ConfirmationModal>
		</div>
	);
};

export default Exercise;
