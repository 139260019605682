import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const Rearrange = (props) => {
	const { t } = useTranslation();
	const { id, values, handleStdAnswer, arrangedAnswer, selectedElem } = props;
	const [selectedItem, setSelectedItem] = useState([]);
	const SEPARATOR = "#";

	const disabledButtonAfterClicking = (event) => {
		event.target.disabled = true;
	};

	const handleAnswers = () => {
		setSelectedItem(selectedElem.map((el) => el.answer_id));
	};

	useEffect(() => {
		handleAnswers();
	}, [selectedElem]);

	return (
		<>
			<div className="col-12 mb-5">
				<div className="card">
					<div className="card-body">
						<label className="card-title" id={id} onChange={handleStdAnswer}>
							{arrangedAnswer?.length > 0
								? arrangedAnswer
										.map((el) => el?.answer_id.split("#")[1])
										.join(" ")
								: ""}
							{/* // : t("rearrange.rearrange-placeholder")} */}
						</label>
					</div>
				</div>
			</div>

			<div className="col-12">
				<ToggleButtonGroup
					type="checkbox"
					exclusive={false}
					value={selectedItem}
					onChange={handleStdAnswer}
				>
					{values?.elements?.map((option, idx) => (
						<ToggleButton
							key={idx}
							value={`${idx}${SEPARATOR}${option}`}
							id={idx}
							className={`answerChoice`}
							onClick={disabledButtonAfterClicking}
						>
							{option}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			</div>
		</>
	);
};

export default Rearrange;
