import React, { useMemo, useState, useEffect } from "react";
import { t } from "i18next";
import { actions } from "../../redux/store";
import { CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Button, ProgressBar } from "react-bootstrap";

import ConfirmationModal from "../reuseable-component/Modal";
import FillGaps from "../reuseable-component/QuestionsType/FillGaps";
import Rearrange from "../reuseable-component/QuestionsType/Rearrange";
import ExamCountDownTimer from "../reuseable-component/ExamCountDownTimer";
import AttachmentAssessment from "../reuseable-component/AttachmentAssessment";
import RadioQuestions from "../reuseable-component/QuestionsType/RadioQuestions";
import CheckQuestions from "../reuseable-component/QuestionsType/CheckBoxQuestions";
import FillGapsSelection from "../reuseable-component/QuestionsType/FillGapsSelection";
// import DragAndDropQuestion from "../reuseable-component/QuestionsType/DragAndDropQuestion";
import TrueAndFalseQuestions from "../reuseable-component/QuestionsType/TrueAndFalseQuestions";

import {
  postPlacementAnswers,
  postAssessmentAnswers,
  postExitQuestions,
} from "../../services/exercisesService";
import { QuestionTypes } from "../../interfaces/exercises";
import "./styles.scss";

const ExercisesEngine = (props) => {
  const { type, questionsList, questionsTotalCount, placementTitle } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [now, setNow] = useState(0);
  const [tempQ, setTempQ] = useState([]);
  const [qHeader, setQHeader] = useState("");
  const [switchFlag, setSwitchFlag] = useState([]);
  const [radioValue, setRadioValue] = useState([]);
  const [studentAnswer, setStudentAnswer] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const listOfAnswers = useSelector((state) => state.answersList);
  const [showBookMarkModal, setShowBookMarkModal] = useState(false);
  const [bookmarkedQuestions, setBookmarkedQuestions] = useState([]);
  let QTitle = questionsList[currentQuestion]?.title;
  let attachmentType = questionsList[currentQuestion]?.attachment?.media_type;
  let attachmentMedia = questionsList[currentQuestion]?.attachment?.media;

  const fillGapsTypes =
    questionsList[currentQuestion]?.question_type !==
      QuestionTypes.FILL_GAPS_TEXT &&
    questionsList[currentQuestion]?.question_type !==
      QuestionTypes.FILL_GAPS_DROPDOWN &&
    questionsList[currentQuestion]?.question_type !==
      QuestionTypes.FILL_GAPS_SELECTION;

  const unSupportedTypes =
    questionsList[currentQuestion]?.question_type ===
      QuestionTypes.REARRANGE_LETTERS ||
    questionsList[currentQuestion]?.question_type ===
      QuestionTypes.REARRANGE_WORDS ||
    questionsList[currentQuestion]?.question_type ===
      QuestionTypes.REARRANGE_SENTENCES;

  const choicesWithMediaTypes =
    questionsList[currentQuestion]?.question_type ===
      QuestionTypes.SINGLE_MEDIA ||
    questionsList[currentQuestion]?.question_type === QuestionTypes.MULTI_MEDIA;

  // with add & remove filter
  const CheckHandler = (e, elem) => {
    const isChecked = e.target.checked;
    const questionsData =
      questionsList[currentQuestion]?.question_type ===
        QuestionTypes.SINGLE_CHOICE ||
      questionsList[currentQuestion]?.question_type ===
        QuestionTypes.MULTI_CHOICE ||
      questionsList[currentQuestion]?.question_type ===
        QuestionTypes.SINGLE_MEDIA ||
      questionsList[currentQuestion]?.question_type ===
        QuestionTypes.MULTI_MEDIA
        ? {
            id: Number(e.target.id),
            answer_id: Number(e.target.value),
          }
        : questionsList[currentQuestion]?.question_type !==
          QuestionTypes.TRUE_AND_FALSE
        ? {
            id: Number(e.target.id),
            answer_id: e.target.value,
          }
        : {
            id: Number(e.target.id.split("-")[0]),
            is_true: Number(e.target.value),
          };
    if (elem !== "" && elem !== undefined && elem.length > 0) {
      setRadioValue((prev) => [...prev.concat(elem)]);
    }
    let filterList = [];

    switch (questionsList[currentQuestion]?.question_type) {
      case QuestionTypes.SINGLE_CHOICE: // This Case For Single Choices Questions [Radio]
      case QuestionTypes.SINGLE_MEDIA:
        filterList.push(questionsData);
        setStudentAnswer(filterList.sort((a, b) => a.id - b.id));
        break;
      case QuestionTypes.MULTI_CHOICE:
      case QuestionTypes.MULTI_MEDIA: // This Case For Multi Choices Questions [Checkbox]
        if (isChecked) {
          filterList = studentAnswer?.filter((item) => {
            return item["id"] !== Number(questionsData.id);
          });
          filterList.push(questionsData);
          setStudentAnswer(filterList.sort((a, b) => a.id - b.id));
        } else {
          filterList = studentAnswer?.filter((item) => {
            return item["id"] !== Number(questionsData.id);
          });
          setStudentAnswer(filterList);
        }
        break;
      case QuestionTypes.REARRANGE_LETTERS:
      case QuestionTypes.REARRANGE_WORDS:
      case QuestionTypes.REARRANGE_SENTENCES:
        filterList = studentAnswer?.filter((item) => {
          return item["id"] !== Number(questionsData.id);
        });
        filterList.push(questionsData);
        setStudentAnswer(filterList);
        break;
      case QuestionTypes.DRAG_AND_DROP: // Drag and drop
        filterList = e.target;
        setStudentAnswer(filterList);
        break;
      default:
        filterList = studentAnswer?.filter((item) => {
          return item["id"] !== Number(questionsData.id);
        });
        filterList.push(questionsData);
        setStudentAnswer(filterList.sort((a, b) => a.id - b.id));
        break;
    }
  };

  const handleResetAllAnswers = () => {
    setRadioValue([]);
    setStudentAnswer([]);
    setTempQ([]);
    resetDisabledPropForToggleBtn();
    setSwitchFlag([]);
  };

  let fillTheGapsQuestions =
    switchFlag?.length > 0
      ? [...switchFlag]
      : tempQ?.length > 0
      ? [...tempQ]
      : questionsList[currentQuestion]?.answers?.map((question) => {
          if (
            questionsList[currentQuestion]?.question_type ===
            QuestionTypes.FILL_GAPS_SELECTION
          )
            return question.value.replace(/#/g, "_");
          else return;
        });

  const questionsUpdate = useMemo(
    () => fillTheGapsQuestions,
    [fillTheGapsQuestions, currentQuestion]
  );

  const resetDisabledPropForToggleBtn = () => {
    Array.prototype.slice
      .call(document.getElementsByClassName("answerChoice"))
      .map((element) => {
        element.disabled = false;
      });
  };

  const updateFillGapsQuestionList = (choice, optionalValue) => {
    let updatedQuestionsList = [...questionsUpdate],
      replacementVal = 0;
    if (optionalValue) {
      if (
        questionsList[
          optionalValue == "back" ? currentQuestion - 1 : currentQuestion + 1
        ].question_type !== QuestionTypes.FILL_GAPS_SELECTION
      )
        return;
      updatedQuestionsList = questionsList[
        optionalValue == "back" ? currentQuestion - 1 : currentQuestion + 1
      ]?.answers?.map((question) => {
        return question.value.replace(/#/g, "_");
      });
    }

    updatedQuestionsList = updatedQuestionsList[0].split(" ");
    for (var i = 0; i < updatedQuestionsList?.length; i++) {
      if (updatedQuestionsList[i].includes("_")) {
        updatedQuestionsList[i] = updatedQuestionsList[i].replace(
          "_",
          optionalValue
            ? choice[replacementVal] !== undefined
              ? `<span style="color:#1AB831;">${
                  choice[replacementVal].split("#")[1]
                }</span>`
              : "_"
            : `<span style="color:#1AB831;">${choice}</span>`
        );
        replacementVal++;

        if (optionalValue == undefined) break;
      }
    }
    updatedQuestionsList = updatedQuestionsList.join(" ");
    optionalValue
      ? setSwitchFlag([updatedQuestionsList])
      : setTempQ([updatedQuestionsList]);
  };

  //this function used in the fill gaps component after clicking on toggle button
  const handleAnswer = (event, e) => {
    if (e !== null) {
      setRadioValue((prev) => [...prev, e]);
      setStudentAnswer((checkArr) => [...checkArr.concat(e)]);
      updateFillGapsQuestionList(event.target.innerText);
      setSwitchFlag([]);
    }
  };

  const TestResult = async () => {
    let updatedAnswers = [...listOfAnswers];

    let updatedCheckedArr = studentAnswer.map((element) => {
      if (element.hasOwnProperty("is_true")) {
        if (!isNaN(element.is_true) && element.is_true !== null) return element;
      } else if (
        typeof element.answer_id === "string" &&
        element.answer_id.includes("#")
      ) {
        return element.answer_id.split("#")[1];
      } else {
        return element.answer_id;
      }
    });

    updatedAnswers = updatedAnswers.map((element, idx) => {
      return {
        id: element.id,
        answer: element.answer.map((p) => {
          if (p.hasOwnProperty("is_true")) {
            if (!isNaN(p.is_true) && p.is_true !== null) return p;
          } else {
            return p.hasOwnProperty("answer_id")
              ? typeof p.answer_id === "string" && p.answer_id.includes("#")
                ? p.answer_id.split("#")[1]
                : p.answer_id
              : typeof p === "string" && p.includes("#")
              ? p.split("#")[1]
              : p;
          }
        }),
      };
    });

    updatedAnswers.push({
      id: questionsList[currentQuestion]?.question_id,
      answer: updatedCheckedArr,
    });

    updatedAnswers = updatedAnswers.map((element, idx) => {
      return {
        id: element.id,
        answer: element.answer.filter((el) => el !== undefined),
      };
    });

    const res =
      type === "placement"
        ? await postPlacementAnswers(updatedAnswers)
        : type === "exit"
        ? await postExitQuestions(updatedAnswers)
        : await postAssessmentAnswers(updatedAnswers);

    if (type === "assessment") {
      dispatch(
        actions.assessmentResult({
          type: "assessment",
          payload: {
            score: res,
          },
        })
      );
    } else if (type === "exit") {
      dispatch(
        actions.assessmentResult({
          type: "exit",
          payload: {
            score: res,
          },
        })
      );
    } else {
      dispatch(
        actions.placementResult({
          type: "placement",
          payload: {
            score: res.data,
          },
        })
      );
    }

    navigate("/assessment-result", {
      replace: true,
      state: { page: { name: location?.pathname, key: location.state } },
    });
  };

  // handling back action with affecting progress bar with current step
  const handleBack = () => {
    let clonedTemp = "back";
    setTempQ([]);
    resetDisabledPropForToggleBtn();
    setRadioValue([]);
    setCurrentQuestion(currentQuestion - 1);

    listOfAnswers.map((element) => {
      const convertEle = element;
      if (convertEle.id === questionsList[currentQuestion - 1]?.question_id) {
        setStudentAnswer(convertEle.answer);
        updateFillGapsQuestionList(convertEle.answer, clonedTemp);
      }
    });
  };

  // handle next action with displaying the step progress on progress bar
  const handleNext = () => {
    let clonedTemp = "Next";
    setTempQ([]);
    setSwitchFlag([]);
    resetDisabledPropForToggleBtn();
    setRadioValue("___");

    dispatch(
      actions.AnswerList({
        type: questionsList[currentQuestion]?.question_type,
        payload: {
          id: questionsList[currentQuestion]?.question_id,
          answer: studentAnswer,
        },
      })
    );
    setStudentAnswer([]);

    //this part is about checking if user click next and there is data for the Q on redux
    listOfAnswers.map((element) => {
      let convertEle = element;
      if (convertEle.id === questionsList[currentQuestion + 1]?.question_id) {
        setStudentAnswer(convertEle.answer);
        updateFillGapsQuestionList(convertEle.answer, clonedTemp);
      }
    });

    if (currentQuestion + 1 === questionsTotalCount) {
      bookmarkedQuestions.length === 0
        ? TestResult()
        : setShowBookMarkModal(true);
    } else {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  // handle bookmarking questions
  const toggleBookmark = () => {
    setBookmarkedQuestions((prev) =>
      prev.includes(currentQuestion)
        ? prev.filter((q) => q !== currentQuestion)
        : [...prev, currentQuestion]
    );
  };

  // handle the progress on current step.
  useEffect(() => {
    setNow(Math.floor((currentQuestion / questionsTotalCount) * 100));
    questionHeader();
  }, [now, currentQuestion]);

  let fillTheGapsAnswers = questionsList[currentQuestion]?.answers?.map(
    (question) => {
      return question?.selects;
    }
  );

  const DisplayQuestionsType = (ques, isChecked) => {
    const QType = questionsList[currentQuestion]?.question_type;
    switch (QType) {
      case QuestionTypes.SINGLE_CHOICE:
      case QuestionTypes.SINGLE_MEDIA:
        return (
          <RadioQuestions
            checkedStatus={
              studentAnswer.find((cur) => cur.id == ques.id) ? true : false
            }
            handleChoiceAnswer={CheckHandler}
            id={ques.id}
            values={ques.value}
            attachment={ques.attachment}
            selectedElem={radioValue}
            listOfAnswers={listOfAnswers}
            questionsList={questionsList}
            currentQuestion={currentQuestion}
            Qtype={QType}
          />
        );
      case QuestionTypes.MULTI_CHOICE:
      case QuestionTypes.MULTI_MEDIA:
        return (
          <CheckQuestions
            onClick={CheckHandler}
            selectedElem={
              studentAnswer.find((cur) => cur.id == ques.id) ? true : false
            }
            id={ques.id}
            values={ques.value}
            attachment={ques.attachment}
            Qtype={QType}
          />
        );
      case QuestionTypes.TRUE_AND_FALSE:
        return (
          <TrueAndFalseQuestions
            checkedStatus={studentAnswer.find((cur) => cur.id == ques.id)}
            onClick={CheckHandler}
            id={ques.id}
            values={ques}
            QType={QType}
          />
        );
      case QuestionTypes.REARRANGE_LETTERS:
      case QuestionTypes.REARRANGE_WORDS:
      case QuestionTypes.REARRANGE_SENTENCES:
        return (
          <Rearrange
            id={ques.id}
            values={ques}
            arrangedAnswer={studentAnswer}
            selectedElem={studentAnswer}
            handleStdAnswer={CheckHandler}
            QType={QType}
          />
        );
      default:
        break;
    }
  };

  const questionHeader = () => {
    const QType = questionsList[currentQuestion]?.question_type;
    switch (QType) {
      case QuestionTypes.SINGLE_CHOICE:
      case QuestionTypes.SINGLE_MEDIA:
        setQHeader(t(`radio.${QType == 13 ? "media-title" : "title"}`));
        break;
      case QuestionTypes.MULTI_CHOICE:
      case QuestionTypes.MULTI_MEDIA:
        setQHeader(t(`checkbox.${QType == 14 ? "media-title" : "title"}`));
        break;
      case QuestionTypes.TRUE_AND_FALSE:
        setQHeader(t(`true-and-false.title`));
        break;
      case QuestionTypes.REARRANGE_LETTERS:
      case QuestionTypes.REARRANGE_WORDS:
      case QuestionTypes.REARRANGE_SENTENCES:
        setQHeader(
          t(
            `rearrange.rearrange-${
              QType == 9 ? "letters" : QType == 10 ? "word" : "sentence"
            }-placeholder`
          )
        );
        break;
      case QuestionTypes.DRAG_AND_DROP:
        setQHeader(t("drag-and-drop.title"));
        break;
      default:
        setQHeader(
          t(
            `fill-gaps.${
              QType == 3 ? "text" : QType == 7 ? "dropdown" : "selection"
            }-title`
          )
        );
        break;
    }
  };

  return (
    <>
      <Row className="g-0 text-center" dir="ltr">
        <Col
          xs={12}
          className="attend-assessment__questions-wrapper justify-content-center"
        >
          <ExamCountDownTimer type={type} TestResult={() => TestResult()} />
        </Col>
        <Col xs={12} className="attend-assessment__questions-wrapper">
          <div className="attend-assessment__questions-title">
            {t("attend-assessment.questions")}
          </div>
          <span className="attend-assessment__questions-number">
            <span>
              {currentQuestion + 1}/{questionsTotalCount}
            </span>
          </span>
        </Col>
        <Col xs={12} className="my-3">
          <ProgressBar now={now} />
        </Col>
      </Row>
      <div className="placement mt-5">
        <CSSTransition key={currentQuestion} classNames="fade" timeout={500}>
          {/*section for the question */}
          <section
            className={
              attachmentType !== undefined
                ? "col-md-6 col-xs-12 placement__question"
                : "col-md-12  placement__question"
            }
          >
            <div className="row">
              <h4
                className={`mb-3 englease__txt--main fill-gaps-selection__title col-8`}
              >
                {qHeader}
              </h4>
              <div className="col-4">
                <Button
                  variant="outline-danger"
                  onClick={toggleBookmark}
                  className={`bookmark-btn ${
                    bookmarkedQuestions.includes(currentQuestion)
                      ? "active"
                      : ""
                  }`}
                >
                  {t("confirmation-message.bookmark-message-1")}
                </Button>
              </div>
            </div>

            {questionsList[currentQuestion]?.question_type ==
              QuestionTypes.FILL_GAPS_TEXT && (
              <h5>{t("fill-gaps.text-sub-title")}</h5>
            )}
            {fillGapsTypes && (
              <p
                className={`mb-3 englease__txt--main`}
                dangerouslySetInnerHTML={{ __html: `${QTitle}` }}
              ></p>
            )}
            {questionsList[currentQuestion]?.question_type ==
              QuestionTypes.MULTI_CHOICE ||
            questionsList[currentQuestion]?.question_type ==
              QuestionTypes.MULTI_MEDIA ? (
              <p>
                <img src="/assets/images/comment-info.svg" alt="hint" />
                &nbsp;
                {t("attend-assessment.hint-goes-here-if-needed")}
              </p>
            ) : null}
            <div
              className={`${
                questionsList[currentQuestion]?.question_type ==
                QuestionTypes.FILL_GAPS_TEXT
                  ? "d-flex align-items-center gap-4"
                  : ""
              }`}
            >
              <div className="row">
                {questionsList[currentQuestion]?.answers?.map((ansObj, i) => (
                  <section
                    className={`col-12 ${
                      choicesWithMediaTypes ? "col-md-6" : ""
                    }`}
                    key={i}
                  >
                    {DisplayQuestionsType(ansObj)}
                  </section>
                ))}
                {questionsList[currentQuestion]?.question_type ===
                  QuestionTypes.FILL_GAPS_TEXT ||
                questionsList[currentQuestion]?.question_type ===
                  QuestionTypes.FILL_GAPS_DROPDOWN ? (
                  <FillGaps
                    questionType={questionsList[currentQuestion]?.question_type}
                    questionsList={questionsList[currentQuestion]?.answers}
                    elementType={questionsList[currentQuestion]?.question_type}
                    handleChoiceAnswer={CheckHandler}
                    checkArr={studentAnswer}
                    resetAllAnswers={handleResetAllAnswers}
                  />
                ) : questionsList[currentQuestion]?.question_type ===
                  QuestionTypes.FILL_GAPS_SELECTION ? (
                  <FillGapsSelection
                    questionAttachment={
                      questionsList[currentQuestion]?.attachment
                    }
                    questionTitle={questionsList[currentQuestion]?.title}
                    questionsList={fillTheGapsQuestions}
                    answersList={fillTheGapsAnswers}
                    handleChoiceAnswer={handleAnswer}
                    resetAllAnswers={handleResetAllAnswers}
                    answersFromRedux={studentAnswer}
                  />
                ) : null}
                {/* questionsList[currentQuestion]?.question_type ===
                  QuestionTypes.DRAG_AND_DROP ? (
                  <DragAndDropQuestion
                    answersFromRedux={studentAnswer}
                    resetAllAnswers={handleResetAllAnswers}
                    question={questionsList[currentQuestion]?.answers}
                    onAnswerChange={(answer) =>
                      CheckHandler({ target: [...answer] })
                    }
                  />
                ) : null} */}
              </div>
            </div>
            {/* {fillGapsTypes && ( */}
            <button
              className="englease__txt--main fill-gaps-selection__resetBtn"
              onClick={handleResetAllAnswers}
            >
              {t("attend-assessment.reset-all-answers")}
            </button>
            {/* )} */}
          </section>
        </CSSTransition>
        {/*section for the attachment */}
        {attachmentType !== undefined && (
          <section className="col-md-6 col-xs-12 ">
            <AttachmentAssessment
              type={attachmentType}
              media={attachmentMedia}
            />
          </section>
        )}
      </div>
      <Row className="g-0 align-items-center" dir="ltr">
        <Col xs={6}>
          {currentQuestion > 0 && (
            <div className="attend-assessment__prev-btn" onClick={handleBack}>
              {t("attend-assessment.previous-question")}
            </div>
          )}
        </Col>
        <Col xs={6} className="attend-assessment__next-holder">
          <Button
            className="englease--bg--main englease--txt--white border-0 attend-assessment__next-btn"
            onClick={handleNext}
            name="next"
          >
            <img src="/assets/icons/Right-arrow.svg" className="py-1" />
          </Button>
        </Col>
      </Row>

      <ConfirmationModal
        show={showBookMarkModal}
        size="md"
        ok={t("confirmation-message.yes-sure")}
        cancel={t("confirmation-message.continue")}
        confirm={() => TestResult()}
        onHide={() => setShowBookMarkModal(false)}
        addclasses="exitBtn"
        type="exitTest"
      >
        <p>
          {t("confirmation-message.bookmark-message")}
          <b style={{ color: "purple" }}>
            {bookmarkedQuestions.length}
            {t("confirmation-message.bookmark-message-1")}
          </b>
          {t("confirmation-message.bookmark-message-2", {
            questionCount:
              bookmarkedQuestions.length == 1 ? t("question") : t("questions"),
          })}
        </p>
      </ConfirmationModal>
    </>
  );
};

export default ExercisesEngine;
