import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { getCookie } from "../../utils";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	Box,
	Checkbox,
	Chip,
	FormGroup,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	useTheme,
} from "@mui/material";
import {
	postStudentCommitmentForm,
	getStudentLevel,
} from "../../services/studentService";

import "./styles.scss";

const CommitmentPage = () => {
	const { t } = useTranslation();
	const locationData = useLocation();
	const detectLang = getCookie("i18next");
	let token = localStorage.getItem("token");
	const [studentLvl, setStudentLvl] = useState(0);
	const [msgStatus, setMsgStatus] = useState(false);
	const [msgDisplay, setMsgDisplay] = useState(false);
	const urlParams = new URLSearchParams(location.search);
	const studentUniversity = urlParams.get("university");
	const [commitmentMessage, setCommitmentMessage] = useState("");

	const learningTime = [
		"8:00-11:00",
		"12:00-15:00",
		"16:00-19:00",
		"20:00-23:00",
	];

	const getStudentLvl = async () => {
		try {
			const studentLevel = await getStudentLevel();
			if (studentLevel.success == true) {
				setStudentLvl(studentLevel?.data[0]?.order);
			} else {
				setMsgStatus(false);
				setCommitmentMessage(studentLevel.message);
				setMsgDisplay(true);
			}
		} catch (err) {
			errorHandler(err.response.status);
		}
	};

	const handleSubmission = async (values, { setSubmitting }) => {
		try {
			const studentCommitmentRes = await postStudentCommitmentForm(values);
			if (studentCommitmentRes.success == true) {
				setMsgStatus(true);
				setCommitmentMessage(studentCommitmentRes.message);
				setMsgDisplay(true);
				setTimeout(() => {
					location.replace(`${process.env.REACT_APP_REDIRECTION_LINK}`);
					// navigate("/student-consent");
				}, 2000);
			} else {
				setMsgStatus(false);
				setCommitmentMessage(studentCommitmentRes.message);
				setMsgDisplay(true);
			}
		} catch (err) {
			setCommitmentMessage(err.message);
			// errorHandler(err.response.status);
		}
	};

	const theme = useTheme();
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: 48 * 4.5 + 8,
				width: 250,
			},
		},
	};
	const getStyles = (name, personName, theme) => {
		return {
			fontWeight:
				personName.indexOf(name) === -1
					? theme.typography.fontWeightRegular
					: theme.typography.fontWeightMedium,
		};
	};
	const inputStyle = () => {
		if (detectLang === "ar") {
			return {
				"& .MuiInputLabel-root": {
					right: 0,
					transformOrigin: "center right",
				},
			};
		}
	};

	useEffect(() => {
		if (urlParams.get("token")) {
			const studentToken = urlParams.get("token");
			localStorage.setItem("token", JSON.stringify(studentToken));
			getStudentLvl();
		} else {
			setStudentLvl(locationData?.state?.page?.userLvl);
		}
	}, []);

	return (
		<section className="commitment">
			<Formik
				initialValues={{ preferred_learning_time: [] }}
				onSubmit={handleSubmission}
			>
				{({ values, errors, isValidating, handleChange, isSubmitting }) => (
					<Form>
						<div className="row commitment__question-wrapper">
							<center>
								<div className="col commitment__img-wrapper">
									<img src="/assets/images/commitment-page.png" />
								</div>

								<div className="col commitment__question-wrapper">
									<span className="commitment__title">
										{t("commitment.greeting")}
									</span>
								</div>
							</center>

							<div className="col">
								<p className="commitment__sub-title">
									{t(`commitment.title-${studentLvl > 2 ? "eob" : "general"}`)}
								</p>
								<p className="commitment__sub-title">
									{t(
										`commitment.sub-title${
											studentUniversity === "Higher Colleges of Technology"
												? "-hct"
												: ""
										}`
									)}
								</p>
							</div>
						</div>

						<div className="commitment__question-wrapper">
							<label className="commitment__question">
								{t("commitment.statement-1")}
							</label>

							<FormGroup>
								<FormControlLabel
									required
									control={<Checkbox color="secondary" />}
									label={t("commitment.i-understand")}
								/>
							</FormGroup>
						</div>

						<div className="commitment__question-wrapper">
							<label className="commitment__question">
								{t("commitment.statement-2")} {studentLvl > 2 ? 30 : 40}
								{t("commitment.statement-2-1")}.
							</label>

							<FormGroup>
								<FormControlLabel
									required
									control={<Checkbox color="secondary" />}
									label={t("commitment.i-commit")}
								/>
							</FormGroup>
						</div>

						<div className="commitment__question-wrapper">
							<label className="commitment__question">
								{t("commitment.statement-3")}
							</label>

							<FormGroup>
								<FormControlLabel
									required
									control={<Checkbox color="secondary" />}
									label={t("commitment.i-commit")}
								/>
							</FormGroup>
						</div>

						<div className="commitment__question-wrapper">
							<label className="commitment__question">
								{t("commitment.statement-4")}
								{t(
									`commitment.statement-4-hint-${
										studentLvl <= 2
											? "general"
											: studentUniversity === "Higher Colleges of Technology"
											? "hct"
											: "eob"
									}`
								)}
							</label>

							<FormGroup>
								<FormControlLabel
									required
									control={<Checkbox color="secondary" />}
									label={t("commitment.i-commit")}
								/>
							</FormGroup>
						</div>

						<div className="commitment__question-wrapper">
							<label className="commitment__question">
								{t("commitment.statement-5")}
							</label>
							<p>
								{t(
									`commitment.statement-5-hint-${
										studentLvl <= 2
											? "general"
											: studentUniversity === "Higher Colleges of Technology"
											? "hct"
											: "eob"
									}`
								)}
							</p>

							<FormGroup>
								<FormControlLabel
									required
									control={<Checkbox color="secondary" />}
									label={t("commitment.i-commit")}
								/>
							</FormGroup>
						</div>

						<div className="col-12 commitment__question-wrapper">
							<FormControl sx={{ m: 0 }} fullWidth={true}>
								<InputLabel id="demo-multiple-chip-label">
									{t("commitment.statement-6")}
								</InputLabel>
								<Select
									labelId="demo-multiple-chip-label"
									// id="demo-multiple-chip"
									id="preferred_learning_time"
									name="preferred_learning_time"
									multiple
									value={values.preferred_learning_time}
									// onChange={handlePreferredTimeChange}
									onChange={handleChange}
									input={
										<OutlinedInput id="select-multiple-chip" label="Chip" />
									}
									renderValue={(selected) => (
										<Box
											sx={{
												display: "flex",
												flexWrap: "wrap",
												gap: 0.5,
											}}
										>
											{selected.map((value) => (
												<Chip key={value} label={value} />
											))}
										</Box>
									)}
									MenuProps={MenuProps}
								>
									{Array.isArray(learningTime) &&
										learningTime?.map((name) => (
											<MenuItem
												key={name}
												value={name}
												style={getStyles(name, [], theme)}
											>
												{name}
											</MenuItem>
										))}
								</Select>
							</FormControl>
						</div>

						<div className="row commitment__question-wrapper">
							{/* Student Signature*/}
							<label className="commitment__question">
								{t("commitment.statement-7")}
							</label>
							<div className="col-12">
								<TextField
									required
									sx={inputStyle}
									className={"p-0"}
									variant="standard"
									// name="student-sign"
									// onChange={handleChange}
									InputProps={{
										className: "englease englease__txt--main englease--f-16",
									}}
									InputLabelProps={{
										className: "englease__txt--muteTxt englease englease--f-16",
									}}
								/>
							</div>
						</div>

						<div className="commitment__question-wrapper">
							<label className="commitment__question">
								{t(
									`commitment.statement-8${
										studentUniversity === "Higher Colleges of Technology"
											? "-hct"
											: ""
									}`
								)}
							</label>
							<FormGroup>
								<FormControlLabel
									required
									control={<Checkbox color="secondary" />}
									label={t("commitment.i-consent")}
								/>
							</FormGroup>
						</div>

						<div className="commitment__question-wrapper">
							<label className="commitment__question">
								{t("commitment.statement-9")}
							</label>
							<FormGroup>
								<FormControlLabel
									required
									control={<Checkbox color="secondary" />}
									label={t("commitment.i-commit")}
								/>
							</FormGroup>
						</div>

						<div className="row my-4">
							<div className="col">
								<button
									className="btn px-5 py-2 mt-3 englease__border--main englease__bg--main englease__txt--white englease__radius--f-8  englease englease--f-14 btn btn-primary"
									type="submit"
									disabled={isSubmitting}
								>
									{t("commitment.submit")}
								</button>
							</div>
						</div>

						{msgDisplay && (
							<div
								className={`commitment__message--display ${
									msgStatus === true
										? "englease__bg--success--20"
										: "englease__bg--error"
								} position-relative w-100 mt-4 py-3 px-2 englease__radius--f-8`}
							>
								<p className="m-0 englease__txt--black englease englease--f-14 text-start ">
									{commitmentMessage}
								</p>
							</div>
						)}

						<pre className="my-5">
							<span className="commitment__question">
								{t("commitment.requirements-title")}
							</span>

							<p>
								{t(
									`commitment.requirements-1-${
										studentLvl > 2 ? "eob" : "general"
									}`
								)}
							</p>
							<p>{t("commitment.requirements-2")}</p>
							<p>{t("commitment.requirements-3")}</p>
							<p>{t("commitment.requirements-4")}</p>
							<p>{t("commitment.requirements-5")}</p>
							<p>{t("commitment.requirements-6")}</p>
						</pre>

						<center>
							{t("commitment.inquires")}
							<span className="mx-1">
								<a href="mailto:nomu@alghurairfoundation.org">
									nomu@alghurairfoundation.org
								</a>
							</span>
						</center>
						<center>
							{t("commitment.data-rights")}
							<span className="mx-1">
								<a href="mailto:dataprotection@alghurairfoundation.org">
									dataprotection@alghurairfoundation.org
								</a>
							</span>
						</center>
					</Form>
				)}
			</Formik>
		</section>
	);
};

export default CommitmentPage;
