import Axios from "../Axios";

const postStudentCommitmentForm = async (data) => {
	try {
		const res = await Axios.post("api/auth/sign-commitment", data);
		return res.data;
	} catch (error) {
		console.log(error);
	}
};

const getStudentLevel = async () => {
	try {
		const res = await Axios.get("api/user_level");
		return res.data;
	} catch (error) {
		console.log(error);
	}
};

export { postStudentCommitmentForm, getStudentLevel };
