import Axios from "../Axios";
import { errorHandler } from "../utils";
import ServiceHandler from "../utils/ServiceHandler";

// a service created for getting placement data from db.
async function getPlacementQuestions(testId) {
	try {
		const data = await Axios.get(`api/placement-test/${testId}`);
		return data.data.data;
	} catch (e) {
		errorHandler(e.response.status);
	}
}

// a service created for setting placement data from db.
async function postPlacementAnswers(answers) {
	let answersPosted = { answers: answers };
	try {
		const result = await Axios.post(
			`api/placement-test-result/`,
			answersPosted
		);
		return result.data;
	} catch (e) {
		errorHandler(e.response.status);
	}
}

// a service created for getting assessment data from db.
async function getAssessmentQuestions(testId) {
	try {
		return await ServiceHandler(
			"get",
			`api/assessment-new/${testId}`,
			null,
			null
		);
		// return data;
	} catch (e) {
		console.log(e.message);
	}
}

// a service created for setting assessment data from db.
async function postAssessmentAnswers(answers) {
	let answersPosted = { answers: answers };
	try {
		return await ServiceHandler(
			"post",
			`api/assessment-answers-new/`,
			answersPosted,
			null
		);
	} catch (e) {
		console.log(e.message);
	}
}

// a service created for getting levels data from db.
async function getLevel() {
	try {
		const getLvl = await Axios.get(`api/levels`);
		return getLvl.data;
	} catch (e) {
		throw new Error(e.message);
	}
}

// a service created for getting levels data from db.
async function getResults(id) {
	try {
		return await ServiceHandler(
			"get",
			`api/assessment-result-new/${id}`,
			null,
			null
		);
	} catch (e) {
		throw new Error(e.message);
	}
}

// a service created for getting assessment data from db.
async function getExitQuestions() {
	try {
		return await ServiceHandler("get", `api/exit-test/`, null, null);
	} catch (e) {
		console.log(e.message);
	}
}

// a service created for setting exit test data.
async function postExitQuestions(answers) {
	let answersPosted = { answers: answers };
	try {
		return await ServiceHandler(
			"post",
			`api/exit-test-answers/`,
			answersPosted,
			null
		);
	} catch (e) {
		console.log(e.message);
	}
}

export {
	getPlacementQuestions,
	getAssessmentQuestions,
	getExitQuestions,
	postPlacementAnswers,
	postAssessmentAnswers,
	postExitQuestions,
	getLevel,
	getResults,
};
