import React from "react";
import "./styles.scss";

const CheckQuestions = (props) => {
	const { onClick, values, attachment, id, Qtype, selectedElem } = props;

	return (
		<div
			className={`checkbox-wrapper-30 englease--click englease__radius--f-8 mb-4
    ${selectedElem && "englease__bg--main--20 englease__border--main"}
        ${Qtype !== 14 ? "w-100" : ""} `}
		>
			<label className="d-flex align-items-center p-3 gap-3 englease--click">
				<span className="checkbox ">
					<input
						type="checkbox"
						id={id}
						onChange={onClick}
						value={id}
						checked={selectedElem}
					/>
					<svg>
						<use xlinkHref="#checkbox-30" className="checkbox"></use>
					</svg>
				</span>
				<svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
					<symbol id="checkbox-30" viewBox="0 0 22 22">
						<path
							fill="none"
							stroke="currentColor"
							d="M5.5,11.3L9,14.8L20.2,3.3l0,0c-0.5-1-1.5-1.8-2.7-1.8h-13c-1.7,0-3,1.3-3,3v13c0,1.7,1.3,3,3,3h13 c1.7,0,3-1.3,3-3v-13c0-0.4-0.1-0.8-0.3-1.2"
						/>
					</symbol>
				</svg>
				{Qtype === 14 ? (
					<img src={attachment.media} className="checkbox-wrapper-30__image" />
				) : (
					<p className="mb-0">{Qtype !== 14 && values}</p>
				)}
			</label>
		</div>
	);
};
export default CheckQuestions;
