export const QuestionTypes = {
  SINGLE_CHOICE: 1,
  MULTI_CHOICE: 2,
  FILL_GAPS_TEXT: 3,
  FILL_GAPS_ESSAY: 4,
  FILL_GAPS_TEXT_NO_MATTER: 5,
  TRUE_AND_FALSE: 6,
  FILL_GAPS_DROPDOWN: 7,
  FILL_GAPS_SELECTION: 8,
  REARRANGE_LETTERS: 9,
  REARRANGE_WORDS: 10,
  REARRANGE_SENTENCES: 11,
  MATCHING: 12,
  SINGLE_MEDIA: 13,
  MULTI_MEDIA: 14,
  DRAG_AND_DROP: 15,
};
