import React, { lazy, useLayoutEffect } from "react";
import { Routes, Route } from "react-router-dom";

import NotFound from "./Pages/NotFound";
// import ConsentPage from "./Pages/ConsentPage";
import Exercise from "./Pages/Exercise/Exercise";
import CommitmentPage from "./Pages/CommitmentPage";
import AssessmentResult from "./component/AssessmentResult";

const QuestionsAnswer = lazy(() => {
	import("./component/QuestionsAnswer");
});

const App = () => {
	const urlParams = new URLSearchParams(location.search);
	const isEb = urlParams.get("isEb");
	const type = urlParams.get("type");
	const testId = urlParams.get("testId");
	const studentToken = urlParams.get("token");
	const scheduleType = urlParams.get("classTypeId");
	const scheduleTypeId = urlParams.get("scheduleType");
	const enterpriseName = urlParams.get("enterprise");
	const localLang = localStorage.getItem("i18nextLng", "ar");

	useLayoutEffect(() => {
		localStorage.setItem("token", JSON.stringify(studentToken));
		localStorage.setItem("enterpriseName", enterpriseName);
		localStorage.setItem("isEb", isEb);
		sessionStorage.setItem("type", type);
		sessionStorage.setItem("testId", testId);
		sessionStorage.setItem(
			"courseData",
			JSON.stringify({
				class_type_id: scheduleType,
				scheduled_class_type_id: scheduleTypeId,
			})
		);
	}, []);

	const routes = [
		{ path: "", element: <Exercise /> },
		// { path: "student-consent", element: <ConsentPage /> },
		{ path: "student-commitment", element: <CommitmentPage /> },
		{ path: "assessment-result", element: <AssessmentResult /> },
		{ path: "questionsAnswer/:name/:id", element: <QuestionsAnswer /> },
		{ path: "*", element: <NotFound /> },
	];

	return (
		<section
			className={`app ${localLang == "ar" ? "englease-rtl" : "englease"}`}
		>
			<main className="container my-5">
				<Routes>
					{routes.map((el, ix) => {
						return <Route key={ix} path={el.path} element={el.element} />;
					})}
				</Routes>
			</main>
		</section>
	);
};

export default App;
