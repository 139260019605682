import React from "react";
import "./styles.scss";

const RadioQuestions = (props) => {
	const { handleChoiceAnswer, values, attachment, id, Qtype, checkedStatus } =
		props;

	return (
		<label
			htmlFor={id}
			className={`d-flex answerChoice btn-radio englease__radius--f-8 mb-3 p-3 ${
				checkedStatus && "englease__bg--main--20 englease__border--main"
			} ${Qtype !== 13 ? "w-100" : ""}`}
		>
			<input
				type="radio"
				id={id}
				className={`answerChoice`}
				checked={checkedStatus}
				onChange={handleChoiceAnswer}
				name="radio-grp"
				value={id}
			/>
			<div className="svg-holder d-block">
				<svg width="24px" height="24px" viewBox="0 0 24 24">
					<circle cx="10" cy="10" r="9"></circle>
					<path
						d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
						className="inner"
					></path>
					<path
						d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
						className="outer"
					></path>
				</svg>
			</div>
			{Qtype === 13 ? (
				<div>
					<img src={attachment.media} className="btn-radio__image" />
				</div>
			) : (
				<p className="mb-0">{Qtype !== 13 && values}</p>
			)}
		</label>
	);
};

export default React.memo(RadioQuestions);
