import axios from "axios";
import { getCookie } from "../utils";

const Axios = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	mode: "cors",
});

// Add a request interceptor
Axios.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		let token = localStorage.getItem("token");
		config.headers = {
			...config.headers,
			Authorization: token
				? `Bearer ${JSON.parse(token)}`
				: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2RldmVsb3AuZW5nbGVhc2UuY29tL2FwaS9hdXRoL2xvZ2luIiwiaWF0IjoxNjg4Mzg4MjAzLCJleHAiOjE2OTA5ODAyMDMsIm5iZiI6MTY4ODM4ODIwMywianRpIjoiWnYzQUpnb3VSQWkyaVlFYSIsInN1YiI6Ijk3IiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.s1yW3oYtkrkj_emIoWZYvPb4knS0jzyy3ZgdvGmbCyw",
			// : "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2RldmVsb3AuZW5nbGVhc2UuY29tL2FwaS9hdXRoL2xvZ2luIiwiaWF0IjoxNjg2MzE3NjMzLCJleHAiOjE2ODg5MDk2MzMsIm5iZiI6MTY4NjMxNzYzMywianRpIjoiTmtSd3dwQ2ZZeUNSamxCQiIsInN1YiI6IjQ2IiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.2sNc7niJ5DgENH4KahCxiQc9njGky50IxoQ5cixqt_M",
			// : "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2RldmVsb3AuZW5nbGVhc2UuY29tL2FwaS9hdXRoL2xvZ2luIiwiaWF0IjoxNjgzNzE1MDk0LCJleHAiOjE2ODYzMDcwOTQsIm5iZiI6MTY4MzcxNTA5NCwianRpIjoiUExvMXNWODF3bUxaV0pwOCIsInN1YiI6IjQ2IiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.XxlYrHjtANcXxpT4vIk7WhZo3Lm95cLsmGi5Y8sIyUU",
			// : "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2RldmVsb3AuZW5nbGVhc2UuY29tL2FwaS9hdXRoL2xvZ2luIiwiaWF0IjoxNjgwODA4ODMxLCJleHAiOjE2ODM0MDA4MzEsIm5iZiI6MTY4MDgwODgzMSwianRpIjoiVUJjUzA3YkN2aFFnNVJGRCIsInN1YiI6IjUiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.-O12-uo8ixF1XQ6Ew46-ml03Mz547mLGtztJOu1z3vY",
			// "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2RldmVsb3AuZW5nbGVhc2UuY29tL2FwaS9hdXRoL2xvZ2luIiwiaWF0IjoxNjgzNTQxMzc4LCJleHAiOjE2ODYxMzMzNzgsIm5iZiI6MTY4MzU0MTM3OCwianRpIjoiQWFMOEhndDNxNGtMNlJzWSIsInN1YiI6IjUiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0._PHUmwP7Lzu1RESMWayqp-IvwabCpj-29b6XRnAOkfI",
			"Access-Control-Allow-Headers":
				"Origin, X-Requested-With, Content-Type, Accept",
			"Access-Control-Allow-Origin": "*",
			"content-Type": "application/json",
			Accept: "application/json",
			Language: getCookie("i18next"),
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		};
		return config;
	},
	function (error) {
		// Do something with request error
		error.toJSON();
		return Promise.reject(error);
	}
);

// Add a response interceptor
Axios.interceptors.response.use(
	(response) => {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		// response;
		return !response.data.success ? response : response;
	},
	(error) => {
		try {
			if (error.response.status >= 400) {
				if (error.response.status === 401) {
					// localStorage.clear();
					// location.assign("/");
				} else if (error.response.status === 400) {
					console.error(error.response?.data);
				} else {
					console.error(error);
				}
			}
			if (error.response.status >= 500) {
				// location.assign("");
			}
		} catch {
			console.log(error);
			console.log("Server Is Down");
		}
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		return Promise.reject(error);
	}
);

export default Axios;
