import React from "react";
import ReactPlayer from "react-player";
import './styles.scss';

const AttachmentAssessment = (props) => {
	const { type, media } = props;

	return (
		<div className="placement">
			{type == "image" ? (
				<img
					className={`placement__${type}Attachment`}
					src={media}
					alt="test-img"
				/>
			) : (
        <ReactPlayer
					className={`placement__${type}Attachment`}
					controls={true}
					url={media}
				/>
			)}
		</div>
	);
};

export default AttachmentAssessment;
