import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from "@mui/material";

import { claimingByMail } from "../../services/certificateService";

import "./styles.scss";

const CertificateModal = (props) => {
	const { eligible, descriptionMessage, requested, currentUserLevel } = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [sameLevel, setSameLevel] = useState(false);
	const [claim, setClaim] = useState(true);
	const [shareStatus, setShareStatus] = useState(false);
	const [linkedin, setLinkedin] = useState("");
	const [description, setDescription] = useState();
	const courseData = JSON.parse(sessionStorage.getItem("courseData"));
	const userData = JSON.parse(localStorage.getItem("userInfo"));
	const [userName, setUserName] = useState(userData ? userData["name"] : "");
	const [userLevel, setUserLevel] = useState();
	const [levelCompletion, setLevelCompletion] = useState();

	async function getUserCurrentLevel() {
		setUserLevel(currentUserLevel?.name);
		setLevelCompletion(currentUserLevel?.complete);
	}

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleLinkedInSharing = async () => {
		await window.open(`${linkedin}`, "_blank");
		await location.assign(`${process.env.REACT_APP_REDIRECTION_LINK}`);
	};

	const handleClose = () => {
		if (shareStatus == true) {
			location.assign(`${process.env.REACT_APP_REDIRECTION_LINK}`);
			// setOpen(false);
			// location.reload();
		} else {
			setOpen(false);
			location.assign(`${process.env.REACT_APP_REDIRECTION_LINK}`);
		}
	};

	const handleNameChange = (e) => {
		if (e.target.value != "") setUserName(e.target.value);
		else setUserName(userData["name"]);
	};

	const handleStayOnSameLevel = () => {
		// window["dataLayer"].push({
		// 	event: "stay_in_level",
		// 	level_completion: levelCompletion,
		// 	level_name: userLevel,
		// });
		handleClose();
	};

	const eligibleBtnAction = async () => {
		if (eligible && !claim) {
			setSameLevel(false);
			setClaim(true);
			setDescription(t("certificate-popup.certificate-issuer-name"));
		} else if (claim && eligible) {
			const certificateResult = await claimingByMail(userName, courseData);
			if (certificateResult?.data.success == true) {
				// window["dataLayer"].push({
				// 	event: "redeem_certificate",
				// 	level_completion: levelCompletion,
				// 	level_name: userLevel,
				// });
				setDescription(t("certificate-popup.congratulations"));
				setShareStatus(true);
				setLinkedin(certificateResult?.data?.data?.linkedin_certificate_url);
				setClaim(false);
			} else {
				setOpen(true);
			}
		} else if (!eligible) handleClose();
	};

	// useEffect(() => {
	// 	getUserCurrentLevel();
	// }, []);

	return (
		<div className="certificate" onClick={($e) => $e.stopPropagation()}>
			<div
				onClick={handleClickOpen}
				disabled={props.disabled}
				className="btn englease--f-12 mx-2 border-0 englease__bg--main englease__radius--f-8 englease__txt--white"
			>
				{props.buttonName}
			</div>
			<Dialog fullWidth={true} open={open} onClose={handleClose}>
				<>
					<DialogTitle className="mb-2">
						<section className="text-center">
							<DialogActions className="p-0">
								<button onClick={handleClose} className="p-0 btn">
									<span className="englease__txt--muteTxt opacity-50 englease--f-24 mb-0 p-0">
										X
									</span>
								</button>
							</DialogActions>
							<i className={`${props.classPassed}`}></i>
							<div>
								<span className="englease--f-24 englease--bold">
									{props.title}
								</span>
							</div>
							<p className="englease__txt--gray opacity-75 englease--f-14 ">
								{description ? description : descriptionMessage}
							</p>
							{claim && !requested && !shareStatus && (
								<TextField
									fullWidth
									name="Name"
									label={t("certificate-popup.new-name")}
									placeholder={userData ? userData["name"] : ""}
									onChange={handleNameChange}
									inputProps={{ className: "englease__txt--black englease" }}
									InputLabelProps={{
										className:
											"englease englease__txt--black englease--f-14 reset-password--input-position reset-password__label ",
									}}
								/>
							)}
						</section>
					</DialogTitle>
					{!requested && (
						<DialogContent className="p-0">
							<DialogContentText className="d-flex flex-column gap-3">
								{eligible && sameLevel && (
									<button
										onClick={handleStayOnSameLevel}
										className="py-3 englease--f-16 bg-transparent text-center  englease__radius--f-8 englease__border--main englease__txt--main"
									>
										{t("certificate-popup.keep-same-level")}
									</button>
								)}
								{shareStatus == true ? (
									<div className="d-flex align-items-center px-2 englease__bg--secondary  englease__radius--f-8 englease englease--f-14 englease__txt--white ">
										<div className="d-flex align-items-center">
											<i className="iET-linkedin englease__bg--contain iET-s24"></i>
										</div>
										<a
											onClick={handleLinkedInSharing}
											className="d-block w-100 border-0 py-3  text-center englease englease__txt--white englease--f-14 englease--click"
										>
											{t("certificate-popup.linkedin")}
										</a>
									</div>
								) : (
									<button
										onClick={eligibleBtnAction}
										className="d-block w-100 border-0 py-3 englease--f-16 englease__bg--main englease__radius--f-8 englease__txt--white text-center"
									>
										{claim
											? t("certificate-popup.claim-now")
											: eligible
											? t("certificate-popup.claim-and-level-up")
											: t("certificate-popup.continue-my-classes")}
									</button>
								)}
							</DialogContentText>
						</DialogContent>
					)}
				</>
			</Dialog>
		</div>
	);
};

export default CertificateModal;
