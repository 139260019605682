import { useMediaQuery } from "react-responsive";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Row, Col } from "react-bootstrap";

const ExamCountDownTimer = (props) => {
	const { TestResult, type } = props;
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 576px)" });

	return (
		<Row className="g-0 attend-assessment__placement-title">
			<Col xs={12}>
				<div className="text-center">
					<CountdownCircleTimer
						isPlaying={true}
						strokeWidth={6}
						trailColor={"var(--color-success)"}
						colors={"var(--color-mute)"}
						strokeLinecap={"butt"}
						rotation={"clockwise"}
						size={isTabletOrMobile ? 58 : 74}
						// size={72}
						duration={type === "assessment" ? 1800 : 900}
						onComplete={() => {
							TestResult();
						}}
					>
						{({ remainingTime }) =>
							`${Math.floor(remainingTime / 60)}:${remainingTime % 60}`
						}
					</CountdownCircleTimer>
				</div>
			</Col>
		</Row>
	);
};

export default ExamCountDownTimer;
