import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const FillGaps = (props) => {
	const { questionType, questionsList, handleChoiceAnswer, checkArr } = props;
	const { t } = useTranslation();
	const inputRef = useRef(null);
	let elementList = [],
		convertedQuestion;

	const fillGapsEngine = (question, questionType) => {
		const replacementRegEx = /#/gi,
			convertedVal = question?.value.split("");
		let num = 0;
		//Fill-gaps text replacer with elements.
		convertedQuestion = convertedVal.map((el, idx) => {
			let inputElem =
				questionType !== 3 && el.match(replacementRegEx)
					? `<select ref="${inputRef}" key="${idx}" id="${idx}" class="form-select question-text" style="width: 15rem; display: inline; margin: 0 0.25rem;" aria-label="Default select example">
							<option value="" selected>${t("fill-gaps.dropdown-placeholder")}</option>
							${question?.dropdowns[num].map((opt, idx) => {
								return `<option key="${idx}" value="${opt}">
													${opt}
												</option>`;
							})}
						</select>`
					: `<input
							id="${idx}"
							type="text"
							ref="${inputRef}"
							autocapitalize="none"
							placeholder="Your Answer"
							class="form-control question-text"
							style="width: 15rem; display: inline; margin: 0 0.25rem;"
							aria-label="default input example"
						/>`;

			if (el.match(replacementRegEx)) {
				num++;
				elementList.push({ id: idx, answer: "" });
			}
			return el.replace(replacementRegEx, inputElem);
		});

		return (
			<p
				key={`1${question.id}5`}
				className={`fill-gaps fill-gaps__question mb-3 ${
					questionType === 3 && "englease__txt"
				}`}
				dangerouslySetInnerHTML={{ __html: `${convertedQuestion.join("")}` }}
			></p>
		);
	};

	useEffect(() => {
		let listOFElem;
		if (document.getElementsByClassName("question-text")) {
			listOFElem = document.getElementsByClassName("question-text");

			Array.from(listOFElem).forEach((el) => {
				el.addEventListener("change", handleChoiceAnswer);
			});
		}
		return () => {
			Array.from(listOFElem).forEach((el) => {
				el.removeEventListener("change", handleChoiceAnswer);
			});
		};
	});

	useEffect(() => {
		Array.from(document.getElementsByClassName("question-text")).forEach(
			(input, idx) => {
				if (checkArr.length === 0) input.value = "";
				checkArr.map((el) => {
					if (input.id == el.id) input.value = el.answer_id || "";
				});
			}
		);
	}, [checkArr]);

	return (
		<>
			{questionsList.map((ques, idx) => {
				return fillGapsEngine(ques, questionType);
			})}
		</>
	);
};

export default FillGaps;
